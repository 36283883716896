// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_OotKe{cursor:pointer;font-weight:400;color:var(--color-primary-50);text-decoration:none}.zp_OotKe:not(.zp_V6VsN):hover,.zp_OotKe:not(.zp_V6VsN).zp_CNOSF{color:var(--color-text-links);text-decoration:none}.zp_OotKe:not(.zp_V6VsN):hover:not(.zp-button) .zp-icon,.zp_OotKe:not(.zp_V6VsN).zp_CNOSF:not(.zp-button) .zp-icon{color:--color-primary-60;color:var(--zp-icon-hover-color, --color-primary-60)}.zp_OotKe.zp_SBzf1:hover{cursor:not-allowed;color:var(--color-neutral-40)}.zp_OotKe:active,.zp_OotKe.zp_A5JZ0{color:var(--color-primary-70)}.zp_OotKe:active:not(.zp-button) .zp-icon,.zp_OotKe.zp_A5JZ0:not(.zp-button) .zp-icon{color:--color-primary-70;color:var(--zp-icon-active-color, --color-primary-70)}.zp_OotKe.zp_TH7xV{color:var(--color-negative-40)}.zp_l3Jzr{text-decoration:underline}.zp_j8uQW{font-size:12px;font-weight:600;line-height:14px;text-transform:uppercase}.zp_yiGcL{font-size:12px;font-weight:400;line-height:16px;text-decoration:underline}.zp_l3Jzr,.zp_j8uQW,.zp_yiGcL{color:var(--color-text-secondary)}.zp_l3Jzr:hover,.zp_l3Jzr.zp_CNOSF,.zp_j8uQW:hover,.zp_j8uQW.zp_CNOSF,.zp_yiGcL:hover,.zp_yiGcL.zp_CNOSF{color:var(--color-primary-50)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "zp_OotKe",
	"noHoverStyles": "zp_V6VsN",
	"hover": "zp_CNOSF",
	"disabled": "zp_SBzf1",
	"active": "zp_A5JZ0",
	"red": "zp_TH7xV",
	"muted": "zp_l3Jzr",
	"title": "zp_j8uQW",
	"action": "zp_yiGcL"
};
export default ___CSS_LOADER_EXPORT___;
