/**
 * Figma: https://www.figma.com/file/ipHeMZQGTFcBFBI8Nr7pqz/Apollo-Design-System-V2?node-id=4349%3A14669
 */

/* eslint-disable react/prop-types */
import React, { type MouseEvent } from 'react';
import classnames from 'classnames';

// Components
import Icon from 'common/components/ui/Icon';
import Link from 'common/components/ui/Link';
import TooltipOverlayTrigger from 'common/components/ui/TooltipOverlayTrigger';
import type { ButtonProps, ButtonGroupProps, SpacedButtonGroupProps } from './button';

// Libs, Utils
import { hasRenderableDirectChildren } from 'common/utils/react-children';

// Resources
import styles from './index.scss';

type ReactButtonEvent = MouseEvent<HTMLButtonElement>;

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const TEXT = 'text';
const TEXT_ALT = 'text-alt';
const TRANSPARENT = 'transparent';
const TRANSPARENT_ALT = 'transparent-alt';
const TOOLTIP = 'tooltip';
const GROWTH = 'growth';
const TERTIARY = 'tertiary';

/** @param {ButtonProps} props */
const Button = (props: ButtonProps) => {
  const {
    variant = PRIMARY,
    size = 'default',
    children: childrenProp,
    onClick,

    loading,
    loadingText = 'Loading...',
    loadingIcon = 'rotate-right',

    style,
    className,
    labelClassName,

    rounded,
    smallLabel,
    hoverTransitionEffect,
    block,
    notRevealed,
    ellipsis,

    buttonRef,
    id,
    type = 'button',
    form,
    dataCy,
    dataTestId,
    htmlAttributes = {},
    disabled: disabledProp,
    ariaDisabled,
    hover,
    active,

    to,
    href,
    target,
    linkProps = {},
    openInNewTab,

    icon,
    rightIcon,
    showCaret,
    showIconTooltip = false,
    invertedCaret,
    iconOnly,
    customIconSrc,
    customIconSrcStyle,
    customIconName,
    customIconSize,

    tooltipClassName,
    tooltipContent,
    tooltipPlacement = 'top',
    tooltipNoWrap,
    tooltipInteractive,
    tooltipDelayShow = 0,
    tooltipDelayHide = 0,
    disabledTooltipContent,
    tooltipVerticalGap,
    tooltipWithArrow,
    tooltipWrapperClassName,
    preventPropagationOnPointerDown = false,
  } = props;

  /**
   * https://reactjs.org/docs/jsx-in-depth.html#booleans-null-and-undefined-are-ignored
   */
  // TODO: Use hasRenderableDirectChildren from 'common/utils/react-children'
  const hasRenderableChildren = !(
    childrenProp === undefined ||
    childrenProp === null ||
    typeof childrenProp === 'boolean' ||
    (typeof childrenProp === 'string' && childrenProp.trim() === '')
  );

  const hasRenderableDirectChildrenProp = hasRenderableDirectChildren(childrenProp);

  // Since the width is now specified, the iconOnly logic needs to be modified
  const iconAndCaret = (icon || rightIcon) && showCaret;
  const customIconOnly = (customIconName || customIconSrc) && !hasRenderableDirectChildrenProp;

  const isIconOnly = iconAndCaret || customIconOnly ? false : !hasRenderableChildren || iconOnly;

  let leftIcon = icon;
  let disabled = disabledProp || ariaDisabled;
  let children = childrenProp;

  if (loading) {
    leftIcon = loadingIcon;
    disabled = true;
    children = loadingText;
  }

  if (
    !leftIcon &&
    !rightIcon &&
    !showCaret &&
    !hasRenderableChildren &&
    !customIconSrc &&
    !customIconName
  ) {
    return null;
  }

  let tooltipContentToShow = tooltipContent;

  if (disabled && disabledTooltipContent) {
    tooltipContentToShow = disabledTooltipContent;
  }

  // icons have an invisible 2px padding
  const shouldLowerLeftPadding = (leftIcon || customIconSrc) && !isIconOnly;
  const shouldLowerRightPadding = (rightIcon || showCaret) && !isIconOnly;

  const classes = classnames(
    'zp-button',
    styles.button,
    hover && styles.hover,
    active && styles.active,
    disabled && styles.disabled,
    isIconOnly && styles.iconOnly,
    iconAndCaret && styles.iconAndCaret,
    customIconOnly && styles.customIconOnly,
    shouldLowerLeftPadding && styles.lowerLeftPadding,
    shouldLowerRightPadding && styles.lowerRightPadding,
    variant === SECONDARY && styles.secondary,
    variant === TEXT && styles.text,
    variant === TEXT_ALT && styles.textAlt,
    variant === TRANSPARENT && styles.transparent,
    variant === TRANSPARENT_ALT && styles.transparentAlt,
    variant === TOOLTIP && styles.tooltip,
    variant === GROWTH && styles.growth,
    variant === TERTIARY && styles.tertiary,
    loading && styles.loading,
    hoverTransitionEffect && styles.hoverTransitionEffect,
    block && styles.block,
    size === 'large' && styles.large,
    size === 'small' && styles.small,
    size === 'extra-small' && styles.xSmall,
    rounded && styles.rounded,
    className,
  );

  // We want to keep the button enabled for ariaDisabled
  const htmlDisabled = ariaDisabled ? false : disabled;

  // The button should effectively act as a disabled button for ariaDisabled, hence `swallowEvent`
  const onClickFn = ariaDisabled ? swallowEvent : onClick;

  /** @param {ReactButtonEvent} event */
  const handlePreventPropagationOnPointerDown = (event: ReactButtonEvent) => {
    if (preventPropagationOnPointerDown) {
      swallowEvent(event);
    }
  };

  let button = (
    <button
      ref={buttonRef}
      // Ref: https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
      // eslint-disable-next-line react/button-has-type
      type={type}
      form={form}
      style={style}
      className={classes}
      onClick={onClickFn}
      disabled={htmlDisabled}
      aria-label={props['aria-label']}
      aria-disabled={ariaDisabled}
      id={id}
      data-cy={dataCy}
      data-testid={dataTestId}
      title={disabled && showIconTooltip ? tooltipContentToShow : undefined}
      //only attach onPointerDown method when preventPropagationOnPointerDown is true
      {...(preventPropagationOnPointerDown
        ? { onPointerDown: handlePreventPropagationOnPointerDown }
        : {})}
      {...htmlAttributes}
    >
      {(leftIcon || customIconSrc || customIconName) && (
        <Icon
          name={leftIcon}
          className={styles.leftIcon}
          customIconName={customIconName}
          customIconSize={customIconSize}
          customSrc={customIconSrc}
          customSrcStyle={customIconSrcStyle}
        />
      )}
      {hasRenderableChildren && !iconOnly && (
        <div
          className={classnames(
            styles.label,
            smallLabel && styles.smallLabel,
            ellipsis && styles.ellipsis,
            labelClassName,
          )}
          data-elem="button-label"
        >
          {children}
        </div>
      )}
      {rightIcon && <Icon name={rightIcon} className={styles.rightIcon} />}
      {notRevealed && !loading && <Icon name="cloud-download" className={styles.rightIcon} />}
      {showCaret && (
        <Icon
          name={invertedCaret ? 'caret-up-small' : 'caret-down-small'}
          className={styles.caret}
        />
      )}
    </button>
  );

  if (tooltipContentToShow) {
    button = (
      <TooltipOverlayTrigger
        tooltipContent={tooltipContentToShow}
        tooltipClassName={tooltipClassName}
        placement={tooltipPlacement}
        tooltipNoWrap={tooltipNoWrap}
        withArrow={tooltipWithArrow}
        delayShow={tooltipDelayShow}
        delayHide={tooltipDelayHide}
        interactive={tooltipInteractive}
        tooltipWrapperClassName={tooltipWrapperClassName}
        verticalGap={tooltipVerticalGap}
      >
        {button}
      </TooltipOverlayTrigger>
    );
  }

  if (to || href) {
    button = (
      <Link
        to={to}
        href={href}
        target={target}
        openInNewTab={openInNewTab}
        disabled={disabled}
        aria-disabled={ariaDisabled}
        {...linkProps}
      >
        {button}
      </Link>
    );
  }

  return button;
};

export default Button;

/** @param {ButtonGroupProps} props */
export const ButtonGroup = ({ className, children }: ButtonGroupProps) => {
  return <div className={classnames(styles.buttonGroup, className)}>{children}</div>;
};

/** @param {SpacedButtonGroupProps} props */
export const SpacedButtonGroup = ({
  horizontal = false,
  children,
  className,
}: SpacedButtonGroupProps) => {
  return (
    <div className={classnames(horizontal && styles.spacedButtonGroupHorizontal, className)}>
      {children}
    </div>
  );
};

/** @param {ReactButtonEvent} event */
function swallowEvent(event: ReactButtonEvent) {
  event.preventDefault();
  event.stopPropagation();
}
