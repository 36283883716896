/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export type DraftingWindowState = {
  open: boolean;
  id: string | null;
  emailerMessageId: string | null;
  discardOnDraftChange: boolean;
};

export enum CRM_FIELDS_FETCHED_STATUS {
  NEVER = 'never',
  FETCHING = 'fetching',
  FETCHED = 'fetched',
  FAILED = 'failed',
}

const STORE_NAME = 'ui';
const VIDYARD_LIBRARY_INITIAL_STATE = {
  open: false,
};

const INITIAL_STATE = {
  outreachRefreshCount: 0, // number: incremented from a parent component that has Pusher and used by a child component to trigger refreshAsyncOptions

  salesloftRefreshCount: 0, // number: same as outreachRefreshCount

  activeProductTours: [], // Remember all the active product tours as we don't want multiple tours to show up at once.

  closingProductTours: [], // Product tours which are pending to be closed.

  vidyard: VIDYARD_LIBRARY_INITIAL_STATE,

  openModals: [], // Array of modal UUIDs that are open

  mountExtensionPusher: false,

  contextualSidebarOpen: false,

  isContextualSidebarAvailable: false,

  hideIntercomLauncher: false,

  /** Is the user upgrading immediately after signing up? */
  isUpgradingImmediatelyAfterSignUp: false,
  /*  */
  draftingWindowState: {
    open: false,
    id: null,
    emailerMessageId: null,
    discardOnDraftChange: false,
  },

  /*
   Used for manually setting the drawer details.
   eg: Person drawer that has been changed to Contact drawer after saving the prospect
  */
  activeDrawerModel: {
    id: '',
    type: '',
  },

  isExtensionSideTipOpen: false,

  /** Have we fetched fields yet? */
  fieldsFetchedStatus: 'never' as 'never' | 'fetching' | 'fetched' | 'failed',

  /** Have we fetched crm fields */
  crmFieldsFetchedStatus: CRM_FIELDS_FETCHED_STATUS.NEVER,
};

const uiSlice = createSlice({
  name: STORE_NAME,
  initialState: INITIAL_STATE,

  reducers: {
    setModalOpen(state, action) {
      const id = action.payload;
      state.openModals.push(id);
    },

    setModalClosed(state, action) {
      const id = action.payload;
      state.openModals = state.openModals.filter((openId) => openId !== id);
    },

    registerTour(state, action) {
      const tourName = action.payload;
      const updatedActiveProductTours = Array.from(
        new Set(state.activeProductTours.concat(tourName)),
      );

      state.activeProductTours = updatedActiveProductTours;
    },

    unregisterTour(state, action) {
      const tourName = action.payload;
      const updatedActiveProductTours = state.activeProductTours.filter(
        (name) => tourName !== name,
      );

      state.activeProductTours = updatedActiveProductTours;
    },

    /**
     * Request the tour to be closed.
     * @param state
     * @param action
     */
    closeTour(state, action) {
      const tourName = action.payload;
      state.closingProductTours.push(tourName);
    },

    /**
     * Tour close callback. To be called by the tour itself after cleanup.
     * @param state
     * @param action
     */
    closeTourCompleted(state, action) {
      const tourName = action.payload;
      state.closingProductTours = state.closingProductTours.filter((name) => tourName !== name);
    },

    incrementOutreachRefreshCount(state) {
      state.outreachRefreshCount = state.outreachRefreshCount + 1;
    },

    incrementSalesloftRefreshCount(state) {
      state.salesloftRefreshCount = state.salesloftRefreshCount + 1;
    },

    openVidyard(state) {
      state.vidyard.open = true;
    },

    closeVidyard(state) {
      state.vidyard.open = false;
    },

    mountExtensionPusher(state) {
      state.mountExtensionPusher = true;
    },

    openContextualSidebar(state) {
      state.contextualSidebarOpen = true;
    },

    closeContextualSidebar(state) {
      state.contextualSidebarOpen = false;
    },

    initializeContextualSidebar(state) {
      state.isContextualSidebarAvailable = true;
    },

    setHideIntercomLauncher(state, action) {
      state.hideIntercomLauncher = action.payload;
    },

    setIsUpgradingImmediatelyAfterSignUp(state, action) {
      state.isUpgradingImmediatelyAfterSignUp = action.payload;
    },
    setDraftingWindowState(state, action) {
      state.draftingWindowState = action.payload;
    },
    setActiveDrawerModel(state, action) {
      const { id = '', type = '' } = action.payload;
      state.activeDrawerModel.id = id;
      state.activeDrawerModel.type = type;
    },
    resetActiveDrawerModel(state) {
      state.activeDrawerModel.id = '';
      state.activeDrawerModel.type = '';
    },
    openExtensionSideTip(state) {
      state.isExtensionSideTipOpen = true;
    },
    closeExtensionSideTip(state) {
      state.isExtensionSideTipOpen = false;
    },
    setFieldsFetchedStatus(
      state,
      action: {
        payload: (typeof INITIAL_STATE)['fieldsFetchedStatus'];
      },
    ) {
      state.fieldsFetchedStatus = action.payload;
    },
    setCrmFieldsFetchedStatus(
      state,
      action: {
        payload: (typeof INITIAL_STATE)['crmFieldsFetchedStatus'];
      },
    ) {
      state.crmFieldsFetchedStatus = action.payload;
    },
  },
});

export const {
  setModalOpen,
  setModalClosed,
  unregisterTour,
  registerTour,
  incrementOutreachRefreshCount,
  incrementSalesloftRefreshCount,
  openVidyard,
  closeVidyard,
  closeTour,
  closeTourCompleted,
  mountExtensionPusher,
  openContextualSidebar,
  closeContextualSidebar,
  initializeContextualSidebar,
  setHideIntercomLauncher,
  setIsUpgradingImmediatelyAfterSignUp,
  setDraftingWindowState,
  setActiveDrawerModel,
  resetActiveDrawerModel,
  openExtensionSideTip,
  closeExtensionSideTip,
  setFieldsFetchedStatus,
  setCrmFieldsFetchedStatus,
} = uiSlice.actions;

export type PartialState = {
  [STORE_NAME]: typeof INITIAL_STATE;
};

export const selectActiveProductTours = (state: PartialState) =>
  state[STORE_NAME].activeProductTours;
export const selectClosingProductTours = (state: PartialState) =>
  state[STORE_NAME].closingProductTours;
export const selectOutreachRefreshCount = (state: PartialState) =>
  state[STORE_NAME].outreachRefreshCount;
export const selectSalesloftRefreshCount = (state: PartialState) =>
  state[STORE_NAME].salesloftRefreshCount;
export const selectVidyard = (state: PartialState) => state[STORE_NAME].vidyard;
export const selectIsAnyModalOpen = (state: PartialState) =>
  state[STORE_NAME].openModals.length > 0;
export const selectMountExtensionPusher = (state: PartialState) =>
  state[STORE_NAME].mountExtensionPusher;
export const selectContextualSidebarOpen = (state: PartialState) =>
  state[STORE_NAME].contextualSidebarOpen;
export const selectIsContextualSidebarAvailable = (state: PartialState) =>
  state[STORE_NAME].isContextualSidebarAvailable;
export const selectHideIntercomLauncher = (state: PartialState) =>
  state[STORE_NAME].hideIntercomLauncher;
export const selectIsUpgradingImmediatelyAfterSignUp = (state: PartialState) =>
  state[STORE_NAME].isUpgradingImmediatelyAfterSignUp;
export const selectDraftingWindowState = (state: PartialState) =>
  state[STORE_NAME].draftingWindowState;
export const selectActiveDrawerModel = (state: PartialState) => state[STORE_NAME].activeDrawerModel;
export const selectIsExtensionSideTipOpen = (state: PartialState) =>
  state[STORE_NAME].isExtensionSideTipOpen;
export const selectFieldsFetchedStatus = (state: PartialState) =>
  state[STORE_NAME].fieldsFetchedStatus;
export const selectCrmFieldsFetchedStatus = (state: PartialState) =>
  state[STORE_NAME].crmFieldsFetchedStatus;
export default uiSlice.reducer;
