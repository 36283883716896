// See cypress/support/commands.js -> visitExtensionFixture for more context
export const CY_OPTIONS = {
  ENABLE_SALESFORCE: 'enableSalesforce',
  ENABLE_PHONE_CALL: 'enablePhoneCall',
  ENABLE_SALESFORCE_MINITOOLBAR_TOUR: 'enableSalesforceMinitoolbarTour',
  DISABLE_SALESFORCE_MINITOOLBAR_TOUR: 'disableSalesforceMinitoolbarTour',
  DISABLE_PROMOS_AND_TOURS: 'disablePromosAndTours',
  ENABLE_LINKEDIN_AUTO_EXPAND_PROMO: 'enableLinkedinAutoExpandPromo',
  ENABLE_HUBSPOT: 'enableHubspotExtension',
  SHOW_CONNECT_CRM_BANNER: 'showConnnectCrmBanner',
  DISABLE_PERMISSIONS_TOUR: 'disablePermissionsTour',
};

export function cypressOptionCheck(optionToCheck: string) {
  const url = new URL(window.location.href);
  const options = (url.searchParams.get('cy-apollo-options') ?? '').split(',');

  return options.includes(optionToCheck);
}

/**
 * * To add a fixture (mock HTML file that is clean and small in size):
 * - Download the Scripty extension -> https://chrome.google.com/webstore/detail/milkbiaeapddfnpenedfgbfdacpbcbam
 * - Download this script -> https://scripty.abhisheksatre.com/#/share/script_1633429204847
 * - Right click the page you want to save, then select Scripty -> Copy Clean HTML
 *  - If the copy fails, open/close DevTools first + focus on the tab, and run the script again
 *  - If the copy still fails, click on the website settings (near the URL) and make sure Clipboard is enabled
 * - Move the file to cypress/fixtures
 * - Add the filename to the object below with its actual URL
 */
export const cypressMockHtmlUrls = {
  'apollo_marketing.html': 'https://apollo.io/',
  'gmail_drafts.html': 'https://mail.google.com/mail/u/0/#drafts',
  'hubspot_login.html': 'https://app.hubspot.com/login/',
  'regular_linkedin_profile.html': 'https://www.linkedin.com/in/grace-hopper-b37596220/',
  'regular_linkedin_profile_2.html': 'https://www.linkedin.com/in/aanshulsadaria/',
  'regular_linkedin_profile_3.html': 'https://www.linkedin.com/in/juned-shaikh-a04a11142/',
  'regular_linkedin_profile_4.html': 'https://www.linkedin.com/in/maxzheng/',
  'regular_linkedin_profile_5.html': 'https://www.linkedin.com/in/sheena-oberoi-5545a4158/',
  'regular_linkedin_profile_6.html': 'https://www.linkedin.com/in/harry-potter/',
  'regular_linkedin_feed.html': 'https://www.linkedin.com/feed/',
  'regular_linkedin_search.html':
    'https://www.linkedin.com/search/results/people/?keywords=engineer&origin=SWITCH_SEARCH_VERTICAL&sid=O*t',
  'regular_linkedin_search_sheena.html':
    'https://www.linkedin.com/search/results/people/?keywords=sheena&origin=SWITCH_SEARCH_VERTICAL&page=9&position=0&sid=GAc',
  'regular_linkedin_company.html': 'https://www.linkedin.com/company/samsung-electronics/',
  'regular_linkedin_company_people.html': 'https://www.linkedin.com/company/netflix/people/',
  'lightning_salesforce_home.html': 'https://apolloio.lightning.force.com/lightning/page/home',
  'classic_salesforce_home.html': 'https://apolloio.my.salesforce.com/home/home.jsp?source=lex',
  'weekly_calendar_index.html': 'https://calendar.google.com/calendar/u/0/r/week',
  'weekly_calendar_popup_opened.html': 'https://calendar.google.com/calendar/u/0/r',
  'apollo_everywhere_ge.html': 'https://www.ge.com/',
  'apollo_everywhere_google.html': 'https://www.google.com/',
  'apollo_everywhere_random.html': 'https://www.randomwebsite.com/',
  'partial_linkedin_sales_navigator.html': 'https://www.linkedin.com/sales/search/people',
  'compelete_linkedin_sales_navigator.html': 'https://www.linkedin.com/sales/search/people',
  'partial_linkedin_recruiter_search.html': 'https://www.linkedin.com/talent/search',
  'complete_linkedin_recruiter_search.html': 'https://www.linkedin.com/talent/search',
  'regular_linkedin_search_2.html':
    'https://www.linkedin.com/search/results/people/?keywords=messi&origin=CLUSTER_EXPANSION&sid=3Qt',
  'hubspot_contact_list.html':
    'https://app.hubspot.com/contacts/39650747/objects/0-1/views/all/list',
  'hubspot_company_list.html':
    'https://app.hubspot.com/contacts/39650747/objects/0-2/views/all/list',
  'hubspot_company_profile.html': 'https://app.hubspot.com/contacts/39650747/company/15633432703',
  'hubspot_contact_profile.html': 'https://app.hubspot.com/contacts/39650747/contact/51',
  'lightning_contact_list_page.html':
    'https://apolloio4-dev-ed.lightning.force.com/lightning/o/Contact/list?filterName=Recent',
};

export function hasCyApolloOptions() {
  const url = new URL(window.location.href);

  return url.searchParams.has('cy-apollo-options');
}
