// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_FV2tX{width:420px;min-width:420px}.zp_Om6BZ{font-size:14px;font-weight:500;line-height:20px;color:var(--color-text-primary);overflow:auto;white-space:pre-wrap}.zp_WcqAs{color:var(--color-caution-40);line-height:normal;margin-right:12px;font-size:22px}.zp_HGOPM{border-bottom:1px solid var(--color-border-light)}.zp_aD75K{display:flex;align-items:center}.zp_mKfYm{display:flex;justify-content:flex-end;border-top:none;padding:10px 10px 10px 20px}.zp_BxgZk{color:inherit}.zp_FC9VL{font-size:16px;font-weight:600;line-height:20px;color:var(--color-text-secondary)}.zp_KZbjw{font-size:14px;font-weight:500;line-height:20px;color:var(--color-text-secondary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alertModal": "zp_FV2tX",
	"alertBody": "zp_Om6BZ",
	"alertIcon": "zp_WcqAs",
	"modalHeader": "zp_HGOPM",
	"modalTitle": "zp_aD75K",
	"modalFooter": "zp_mKfYm",
	"alertLink": "zp_BxgZk",
	"bodyMessage": "zp_FC9VL",
	"supportText": "zp_KZbjw"
};
export default ___CSS_LOADER_EXPORT___;
