import React, { type ComponentProps, type PropsWithChildren, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';

import Tooltip, { CleanTooltip } from 'common/components/ui/Tooltip';
import type { TippyProps } from '@tippyjs/react';

export type TooltipOverlayTriggerProps = PropsWithChildren<{
  withArrow?: boolean;
  tooltipContent?: React.ReactNode;
  tooltipClassName?: string;
  tooltipNoWrap?: boolean;
  showUnderline?: boolean;
  delayShow?: number;
  delayHide?: number;
  verticalGap?: number;
  onShow?(...args: unknown[]): unknown;
  onHide?(...args: unknown[]): unknown;
  tooltipWrapperClassName?: string;
}> &
  Pick<
    TippyProps,
    | 'placement'
    | 'interactive'
    | 'reference'
    | 'visible'
    | 'showOnCreate'
    | 'appendTo'
    | 'zIndex'
    | 'hideOnClick'
  >;

export default function TooltipOverlayTrigger({
  placement = 'left',
  withArrow = false,
  tooltipContent = null,
  tooltipNoWrap = false,
  showUnderline = false,
  delayShow = 250,
  delayHide = 0,
  verticalGap = undefined,
  children = null,
  tooltipClassName = '',
  interactive = false,
  tooltipWrapperClassName = undefined,
  onShow = () => {},
  onHide = () => {},
  appendTo,
  showOnCreate = false,
  hideOnClick = true,
  visible,
  zIndex,
  reference,
}: TooltipOverlayTriggerProps) {
  const tooltipRef = useRef(null);
  const [cleanTooltipRef, setCleanTooltipRef] = useState(null);

  if (isEmpty(tooltipContent)) {
    return <>{children}</>;
  }

  /*
      Tippy props - these should be final, if you want to add other props
            take a look at the className and showUnderline usage.

      If ever you need to add Tippy props, check https://atomiks.github.io/tippyjs/v6/all-props/
    */
  const options: NonNullable<ComponentProps<typeof Tooltip>['options']> = {
    appendTo:
      appendTo ??
      (() =>
        tooltipRef?.current
          ? tooltipRef?.current?.closest('body')
          : cleanTooltipRef?.closest('body')), // Used for iframe tooltip in extension
    placement,
    arrow: withArrow,
    content: tooltipContent,
    delay: [delayShow, delayHide],
    animation: 'fade',
    zIndex: zIndex ?? 2147483647,
    interactive,
    showOnCreate,
    onShow,
    onHide,
    hideOnClick,
    visible,
    reference,
  };

  if (typeof verticalGap !== 'undefined' && Number.isFinite(verticalGap)) {
    options.offset = [0, verticalGap];
  }

  if (tooltipNoWrap) {
    return (
      <CleanTooltip
        options={options}
        className={tooltipClassName}
        showUnderline={showUnderline}
        setCleanTooltipRef={setCleanTooltipRef}
      >
        {children}
      </CleanTooltip>
    );
  }

  return (
    <Tooltip options={options} className={tooltipClassName} showUnderline={showUnderline}>
      <span ref={tooltipRef} className={tooltipWrapperClassName}>
        {children}
      </span>
    </Tooltip>
  );
}
