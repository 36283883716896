// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_dZ0gM{color:var(--color-icon-normal);font-size:20px}.zp_dZ0gM.zp_xxovr{color:var(--color-icon-light)}.zp_dZ0gM.zp_jD7SR{font-size:10px}.zp_dZ0gM.zp_eA0NK{font-size:12px;font-weight:400;line-height:16px}.zp_dZ0gM.zp_rRy3H{color:#fff}.zp_dZ0gM.zp_dkGN8{color:var(--color-negative-40)}.zp_dZ0gM.zp_AINIf{font-size:14px;font-weight:400;line-height:20px}.zp_dZ0gM.zp_EakDO{font-size:18px}.zp_dZ0gM.zp_biKp_{font-size:30px}.zp_dZ0gM.zp_Y2S82{font-size:100px}.zp_dZ0gM.zp_xWzii{color:inherit}.zp_dZ0gM.zp_cbdp1{font-size:16px;font-weight:400;line-height:24px;color:#fff;margin-right:5px}.zp_dZ0gM.zp_ncn4Z{margin-right:10px}.zp_dZ0gM.zp_QcFBJ{margin-left:10px}.zp_dZ0gM.zp_efSQj{display:flex;align-items:center;justify-content:center}.zp_dZ0gM.zp_aqMtS{color:var(--color-primary-50)}.zp_dZ0gM.zp_koRMu{line-height:1}.zp_foWXB{cursor:pointer}.zp_j49HX:hover{color:var(--color-primary-50)}.zp_j49HX:active{color:var(--color-text-links)}.zp_r9fzS:hover use{fill:var(--color-primary-50)}.zp_y0tUv:hover{color:var(--color-negative-40)}.zp_y0tUv:active{color:var(--color-negative-50)}.zp_c_PCB{color:var(--color-text-caution)}.zp_IGZhA{color:var(--color-positive-50)}.zp_k0Jdn{color:var(--color-secondary-60)}.zp_szIIA{color:var(--color-negative-40)}.zp_S1OVv{color:var(--color-positive-60)}.zp_LXTAl{color:var(--color-negative-40)}.zp_MnMqP{color:var(--color-primary-50)}.zp_x6pAE{color:var(--color-neutral-40)}.zp_iNhkb{color:var(--color-caution-50)}.zp_WATwk{color:var(--color-text-caution)}.zp_d_N7X{color:var(--color-neutral-30)}.zp_SCyDx{color:var(--color-secondary-50)}@keyframes zp_u9iKh{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}.zp_u9iKh,.zp_EHjts::before{animation-name:zp_u9iKh;animation-duration:1.5s;animation-duration:var(--icon-animation-duration, 1.5s);animation-iteration-count:infinite;animation-timing-function:linear}.zp_EHjts::before{animation-direction:reverse}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default": "zp_dZ0gM",
	"light": "zp_xxovr",
	"tiny": "zp_jD7SR",
	"small": "zp_eA0NK",
	"white": "zp_rRy3H",
	"danger": "zp_dkGN8",
	"small-medium": "zp_AINIf",
	"medium": "zp_EakDO",
	"medium-large": "zp_biKp_",
	"large": "zp_Y2S82",
	"no-color": "zp_xWzii",
	"for-badge": "zp_cbdp1",
	"right-padded": "zp_ncn4Z",
	"leftPadded": "zp_QcFBJ",
	"custom-image": "zp_efSQj",
	"active": "zp_aqMtS",
	"adjustLineHeight": "zp_koRMu",
	"clickable": "zp_foWXB",
	"clickable-hover": "zp_j49HX",
	"custom-svg": "zp_r9fzS",
	"color-style-action-danger": "zp_y0tUv",
	"color-style-canvas-dark": "zp_c_PCB",
	"color-style-toast-success": "zp_IGZhA",
	"color-style-toast-warning": "zp_k0Jdn",
	"color-style-toast-error": "zp_szIIA",
	"color-style-status-success": "zp_S1OVv",
	"color-style-status-error": "zp_LXTAl",
	"color-style-blue": "zp_MnMqP",
	"color-style-light": "zp_x6pAE",
	"color-style-orange": "zp_iNhkb",
	"color-style-orange2": "zp_WATwk",
	"color-style-gray4": "zp_d_N7X",
	"color-style-yellow": "zp_SCyDx",
	"rotating": "zp_u9iKh",
	"rotatingClockwise": "zp_EHjts"
};
export default ___CSS_LOADER_EXPORT___;
