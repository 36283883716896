import React, { Component, type ReactNode } from 'react';
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from 'common/components/ui/Modal';
import Icon from 'common/components/ui/Icon';
import Button from 'common/components/design-system/Button';

import styles from './AlertDialog.scss';
import { sanitizeHtmlString } from '../../utils/HTMLUtils';
import type { DialogProps } from 'app/lib/useDialog';

type AlertDialogProps = DialogProps<{
  title?: string;
  message: ReactNode | undefined;
  // If this is set to true, props.message must not come from user input. or you must sanitize props.message if it comes from user input
  renderMessageAsHtml?: boolean;
  showAlertIcon?: boolean;
  buttonTitle?: ReactNode;
  buttonDisabled?: boolean;
  onButtonClick?(...args: unknown[]): unknown;
  supportingText?: string;
  showCancelButton?: boolean;
  verticalCenter?: boolean;
}>;

export default class AlertDialog extends Component<AlertDialogProps> {
  static defaultProps = {
    showAlertIcon: false,
    renderMessageAsHtml: false,
    buttonTitle: 'OK',
    supportingText: undefined,
    showCancelButton: false,
    verticalCenter: false,
    buttonDisabled: false,
    title: 'Alert',
    onButtonClick: undefined,
    onHide: undefined,
  };

  handleButtonClick = () => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }

    if (this.props.onHide) {
      this.props.onHide();
    }
  };

  handleMessageClick = (e: { target: { getAttribute: (arg0: string) => string } }) => {
    if (e.target.getAttribute('aria-onclick') === 'close_alert') {
      this.props.onHide && this.props.onHide();
    }
  };

  renderMessage = () => {
    const { renderMessageAsHtml, supportingText } = this.props;
    let { message } = this.props;

    if (supportingText) {
      return (
        <>
          <p className={styles.bodyMessage}>{message}</p>
          <p className={styles.supportText}>{supportingText}</p>
        </>
      );
    }

    if (!renderMessageAsHtml) {
      return message;
    }

    const closeAlertTags = ["aria-onclick='close_alert'", 'aria-onclick="close_alert"'];
    closeAlertTags.forEach((tagText) => {
      if (typeof message === 'string') {
        message = message.replace(tagText, `${tagText} class=${styles.alertLink}`);
      }
    });

    return (
      <span
        onClick={this.handleMessageClick}
        dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(message) }}
      />
    );
  };

  render() {
    const {
      title,
      buttonTitle,
      buttonDisabled,
      showAlertIcon,
      showCancelButton,
      onHide,
      verticalCenter,
    } = this.props;
    return (
      <Modal
        secondary
        onHide={onHide}
        show
        className={styles.alertModal}
        modalOverlayClass="api-error-modal"
        verticalCenter={verticalCenter}
      >
        <ModalHeader className={styles.modalHeader} closeButton>
          <ModalTitle className={styles.modalTitle}>
            {showAlertIcon ? (
              <Icon name="alert-triangle" className={styles.alertIcon} disableHoverStyling />
            ) : null}
            {title || 'Alert'}
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className={styles.alertBody}>{this.renderMessage()}</div>
        </ModalBody>
        <ModalFooter className={styles.modalFooter}>
          {showCancelButton && (
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
          )}
          <Button onClick={this.handleButtonClick} disabled={buttonDisabled}>
            {buttonTitle}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
