import React, { type ComponentProps, PureComponent } from 'react';
import classnames from 'classnames';
import TooltipOverlayTrigger from 'common/components/ui/TooltipOverlayTrigger';
import { imageUrl } from 'common/utils/urlUtil';
import { loadMaterialIconsStylesheet } from './Icon/material-icons';

import 'fonts/apollo-icons/apollo-icons.css';

import { APOLLO_ICONS_CODEPOINTS } from 'fonts/apollo-icons/apollo-icons';
import ApolloColoredIcons from 'common/components/ui/ApolloColoredIcons';

import type { AllIcons } from '$types/icon';

import styles from './Icon.scss';

/**
 * Returns the apollo-icon name of the a given icon if it exists in apollo-icon
 * @param {string} name
 *
 */
function getApolloIconName(name?: string): string | undefined {
  if (name && name in APOLLO_ICONS_CODEPOINTS) {
    return `apollo-icon-${name}`;
  }
}

export type IconColorStyle =
  | 'action-danger'
  | 'canvas-dark'
  | 'toast-success'
  | 'toast-warning'
  | 'toast-error'
  | 'status-success'
  | 'status-error'
  | 'blue'
  | 'light'
  | 'orange'
  | 'orange2'
  | 'gray4'
  | 'yellow';

type IconCoreProps = {
  name?: AllIcons;
  disableHoverStyling?: boolean;
  /**
   * Prevents .zp-icon class to be included in classList to avoid inheriting styles
   */
  noDefaultClass?: boolean;
  rotatingClockwise?: boolean;
  /**
   * Adjusts line-height equal to font-size
   */
  adjustLineHeight?: boolean;
  /**
   * tiny = 10px
   *
   * small = 12px
   *
   * small-medium = 14px
   *
   * medium = 18px
   *
   * medium-large = 30px
   *
   * large = 100px
   */
  size?: 'tiny' | 'small' | 'small-medium' | 'medium' | 'medium-large' | 'large';
  colorStyle?: IconColorStyle;
  className?: string;
  noColor?: boolean;
  clickable?: boolean;
  light?: boolean;
  innerRef?: React.LegacyRef<HTMLElement>;
  customIconName?: string;
  customIconSize?: string;
  forBadge?: boolean;
  rightPadded?: boolean;
  leftPadded?: boolean;
  white?: boolean;
  danger?: boolean;
  active?: boolean;
  customSvg?: string;
  customSrc?: string;
  disabled?: boolean;
  customSrcStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
};

class IconCore extends PureComponent<IconCoreProps> {
  static defaultProps = {
    disableHoverStyling: false,
    noDefaultClass: false,
    rotatingClockwise: false,
    adjustLineHeight: false,

    size: undefined,
  };

  getIconName = (name?: string) => {
    switch (name) {
      case 'zp-icon-home':
        return 'home';
      case 'zp-icon-analytics':
        return 'chart-bar';
      case 'zp-icon-companies':
        return 'domain';
      case 'zp-icon-people':
        return 'account-multiple';
      case 'zp-icon-sequences':
        return 'send';
      case 'zp-icon-playbooks':
        return 'book-open-variant';
      case 'zp-icon-templates':
        return 'file-document-box';
      case 'zp-icon-outbox':
        return 'mail';
      case 'zp-icon-calls':
        return 'phone';
      case 'zp-icon-tasks':
        return 'checkbox-marked-outline';
      case 'zp-icon-settings':
        return 'settings';
      default:
        return name;
    }
  };

  componentDidMount() {
    this.loadMaterialIconsStylesheetIfRequired();
  }

  componentDidUpdate(prevProps: Readonly<IconCoreProps>): void {
    if (this.props.name !== prevProps.name) {
      this.loadMaterialIconsStylesheetIfRequired();
    }
  }

  loadMaterialIconsStylesheetIfRequired() {
    if (this.props.name && !getApolloIconName(this.props.name)) {
      loadMaterialIconsStylesheet();
    }
  }

  render() {
    const {
      className,
      clickable,
      colorStyle,
      customIconName,
      customIconSize,
      forBadge,
      rightPadded,
      leftPadded,
      noColor,
      size,
      name,
      white,
      danger,
      active,
      light,
      disableHoverStyling,
      adjustLineHeight,
      customSvg,
      customSrcStyle,
      customSrc,
      noDefaultClass,
      rotatingClockwise,
      innerRef,
      ...rest
    } = this.props;

    const apolloIconName = getApolloIconName(name);
    const internalName = this.getIconName(name);

    const isColoredApolloIcon = name && name in ApolloColoredIcons;
    const isApolloIcon = Boolean(apolloIconName) || isColoredApolloIcon;

    const classes = classnames(
      !noDefaultClass && 'zp-icon',

      !isApolloIcon && 'mdi',
      !isApolloIcon && Boolean(internalName) && `mdi-${internalName}`,

      isApolloIcon && 'apollo-icon',
      isColoredApolloIcon && 'apollo-colored-icon',
      !isColoredApolloIcon && apolloIconName,

      styles.default,
      clickable && styles.clickable,
      colorStyle && styles[`color-style-${colorStyle}`],
      size === 'tiny' && styles.tiny,
      size === 'small' && styles.small,
      size === 'small-medium' && styles['small-medium'],
      size === 'medium' && styles.medium,
      size === 'medium-large' && styles['medium-large'],
      size === 'large' && styles.large,
      adjustLineHeight && styles.adjustLineHeight,
      rightPadded && styles['right-padded'],
      leftPadded && styles.leftPadded,
      noColor && styles['no-color'],
      light && styles.light,
      this.props.onClick && styles.clickable,
      !this.props.disableHoverStyling && styles['clickable-hover'],
      forBadge && styles['for-badge'],
      white && styles.white,
      danger && styles.danger,
      customIconName && styles['custom-image'],
      active && styles.active,
      rotatingClockwise && styles.rotatingClockwise,
      className,
    );

    if (customSvg) {
      return <div className={styles['custom-svg']}>{customSvg}</div>;
    } else if (customSrc) {
      const newImageStyle = Object.assign({}, customSrcStyle || {});
      return <img src={customSrc} style={newImageStyle} alt="" />;
    } else if (customIconName) {
      // The file must exist under public/images/[customIconName]
      return (
        <span className={classes} ref={innerRef} {...rest}>
          <img src={imageUrl(customIconName, true)} style={{ height: customIconSize }} />
        </span>
      );
    }

    const originalOnMouseUp = this.props.onMouseUp;
    const onMouseUp = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (originalOnMouseUp) {
        originalOnMouseUp(e);
      }
    };

    if (isColoredApolloIcon) {
      return (
        <i className={classes} onMouseUp={onMouseUp} ref={innerRef} {...rest}>
          {ApolloColoredIcons[name]}
        </i>
      );
    } else {
      return <i className={classes} onMouseUp={onMouseUp} ref={innerRef} {...rest} />;
    }
  }
}

type IconProps = {
  tip?: ComponentProps<typeof TooltipOverlayTrigger>['tooltipContent'];
  tooltipWithArrow?: ComponentProps<typeof TooltipOverlayTrigger>['withArrow'];
  interactiveTooltip?: ComponentProps<typeof TooltipOverlayTrigger>['interactive'];
  /**
   * Prevents .zp-icon class to be included in classList to avoid inheriting styles
   */
  tooltipVerticalGap?: ComponentProps<typeof TooltipOverlayTrigger>['verticalGap'];
  alt?: string;
  tipPlacement?: ComponentProps<typeof TooltipOverlayTrigger>['placement'];
} & IconCoreProps &
  Pick<
    ComponentProps<typeof TooltipOverlayTrigger>,
    'tooltipClassName' | 'tooltipWrapperClassName' | 'tooltipNoWrap'
  >;

export default class Icon extends PureComponent<IconProps> {
  static defaultProps = {
    customIconSize: '16px',
    noDefaultClass: false,
    interactiveTooltip: false,
    tooltipWithArrow: false,
    tip: undefined,
    tooltipVerticalGap: undefined,
    tooltipWrapperClassName: undefined,
  };

  render() {
    const {
      customIconName,
      customIconSize,
      customSvg,
      customSrc,
      name,
      tip,
      tooltipClassName,
      tipPlacement = 'bottom',
      tooltipWithArrow,
      tooltipNoWrap,
      customSrcStyle,
      interactiveTooltip,
      tooltipVerticalGap,
      tooltipWrapperClassName,
      ...rest
    } = this.props;

    if (!name && !customIconName && !customSvg && !customSrc) {
      return null;
    }

    if (tip) {
      return (
        <TooltipOverlayTrigger
          placement={tipPlacement}
          tooltipContent={tip}
          tooltipClassName={tooltipClassName}
          tooltipNoWrap={tooltipNoWrap}
          interactive={interactiveTooltip}
          withArrow={tooltipWithArrow}
          verticalGap={tooltipVerticalGap}
          tooltipWrapperClassName={tooltipWrapperClassName}
        >
          <IconCore
            name={name}
            customIconName={customIconName}
            customIconSize={customIconSize}
            customSvg={customSvg}
            customSrc={customSrc}
            customSrcStyle={customSrcStyle}
            {...rest}
          />
        </TooltipOverlayTrigger>
      );
    }

    return (
      <IconCore
        name={name}
        customIconName={customIconName}
        customIconSize={customIconSize}
        customSvg={customSvg}
        customSrc={customSrc}
        customSrcStyle={customSrcStyle}
        {...rest}
      />
    );
  }
}
