import { pick } from 'lodash-es';
import React, { Component, type ComponentProps, type PropsWithChildren, createRef } from 'react';
import classnames from 'classnames';
import $ from 'jquery';
import { Modal as ModalOverlay } from 'react-overlays';
import { connect } from 'react-redux';

import { overrideLIOverlaysZIndex } from 'chrome-extension/inject/linkedin/lib/linkedinDOMUtils';
import { getCurrentSite } from 'chrome-extension/inject/core/lib/locationUtils';
import { uuid } from 'app/utils/guidFor';

import { setModalClosed, setModalOpen } from 'app/slices/ui';

import ResetWrapper from 'common/components/ui/ResetWrapper';
import Icon from 'common/components/ui/Icon';

import styles from './Modal.scss';

const MODAL_PROP_KEYS = [
  'autoFocus',
  'backdrop',
  'backdropClassName',
  'backdropStyle',
  'container',
  'containerClassName',
  'keyboard',
  'onBackdropClick',
  'onEnter',
  'onEntered',
  'onEntering',
  'onEscapeKeyUp',
  'onExit',
  'onExited',
  'onExiting',
  'onHide',
  'onShow',
  'show',
  'transition',
] as const satisfies readonly (keyof ComponentProps<typeof ModalOverlay>)[];

export type ModalWidth =
  | 'narrow'
  | 'small'
  | 'medium'
  | 'wide'
  | 'sidebar'
  | 'ultraWide'
  | 'fullScreen'
  | 'custom';

type GenericWrapperWithClassNameProps = PropsWithChildren<{
  baseClassName: string;
  className?: string;
  onScroll?(...args: unknown[]): unknown;
  onClick?(...args: unknown[]): unknown;
}>;

class GenericWrapperWithClassName extends Component<GenericWrapperWithClassNameProps> {
  static defaultProps = {
    onScroll: () => {},
    onClick: () => {},
  };
  render() {
    const { children, baseClassName, className, onScroll, onClick, ...restProps } = this.props;

    const classes = classnames(baseClassName, className);

    return (
      <div className={classes} onScroll={onScroll} onClick={onClick} {...restProps}>
        {children}
      </div>
    );
  }
}

type BaseModalProps = {
  className?: string;
  dialogClassName?: string;
  width?: ModalWidth;
  responsive?: boolean;
  backdropClassName?: string;
  backdropDismissable?: boolean;
  clamped?: boolean;
  /** Does the modal have a separate sidebar? */
  withSeparateSidebar?: boolean;
  disableAutoInputFocus?: boolean;
  modalOverlayClass?: string;
  modalRestWrapperClassName?: string;
  sidebar?: boolean;
  dataCy?: string;
  dataTestId?: string;
  onHide(...args: unknown[]): unknown;
  secondary?: boolean;
  verticalCenter?: boolean;
  children?: React.ReactNode;
  // from redux
  dispatch?: (...args: unknown[]) => unknown;
  show?: boolean;
  /*
  If true, appends 'prevent-external-overrides' class to body. Otherwise chrome extensions
  can conflict with the Modal component's z-index
  */
  preventExternalOverrides?: boolean;
};

type SupportedModalOverlayProps = Omit<
  Pick<ComponentProps<typeof ModalOverlay>, (typeof MODAL_PROP_KEYS)[number]>,
  keyof BaseModalProps
>;

export type ModalProps = SupportedModalOverlayProps & BaseModalProps;

class Modal extends Component<ModalProps> {
  static defaultProps = {
    className: undefined,
    dialogClassName: undefined,
    responsive: true,
    width: 'medium',
    backdropClassName: null,
    backdropDismissable: true,
    clamped: false,
    withSeparateSidebar: false,
    modalOverlayClass: undefined,
    modalRestWrapperClassName: undefined,
    sidebar: false,
    secondary: undefined,
    verticalCenter: undefined,
    children: null,
    dataCy: null,
    show: false,
    preventExternalOverrides: false,
  };

  modalUuid = uuid();

  modalInnerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.props.preventExternalOverrides) {
      document.body.classList.add('prevent-external-overrides');
    }

    if (getCurrentSite() === 'linkedin' || getCurrentSite() === 'apollo_everywhere') {
      overrideLIOverlaysZIndex();
    }

    this.props.backdropDismissable &&
      document.addEventListener('click', this.handleBackdropClick, true);
    document.addEventListener('mousedown', this.handleMouseDown, true);

    if (this.props.show) {
      this.props.dispatch?.(setModalOpen(this.modalUuid));
    }

    if (this.props.disableAutoInputFocus) {
      return;
    }

    // `setTimeout` because we want to wait for next tick for `this.refs.modalInner` to be truthy
    setTimeout(() => {
      const node = this.modalInnerRef.current;
      const inputs = node && Array.from(node.querySelectorAll('form input'));

      if (!inputs || !inputs.length) {
        return;
      }

      for (const input of inputs) {
        const el = $(input);
        if (el && !el.is(':hidden') && el.attr('type') !== 'hidden') {
          el.focus();
          break;
        }
      }
    });
  }

  componentWillUnmount() {
    if (this.props.preventExternalOverrides) {
      document.body.classList.remove('prevent-external-overrides');
    }

    if (getCurrentSite() === 'linkedin' || getCurrentSite() === 'apollo_everywhere') {
      overrideLIOverlaysZIndex({ maybeRemoveOverride: true });
    }

    this.props.backdropDismissable &&
      document.removeEventListener('click', this.handleBackdropClick);
    document.removeEventListener('mousedown', this.handleMouseDown);

    this.props.dispatch?.(setModalClosed(this.modalUuid));
  }

  componentDidUpdate(prevProps: { show: boolean }) {
    if (this.props.show !== prevProps.show) {
      // The `show` prop was toggled

      if (this.props.show) {
        this.props.dispatch?.(setModalOpen(this.modalUuid));
      } else {
        this.props.dispatch?.(setModalClosed(this.modalUuid));
      }
    }
  }

  mouseDownInsideModal = false;

  handleMouseDown = (event: React.MouseEvent) => {
    const node = this.modalInnerRef.current;
    if (node && event.target === node.parentNode) {
      this.mouseDownInsideModal = false;
    } else {
      this.mouseDownInsideModal = true;
    }
  };

  handleBackdropClick = (event: React.MouseEvent) => {
    const node = this.modalInnerRef.current;
    if (node && event.target === node.parentNode && !this.mouseDownInsideModal) {
      // https://sentry.io/organizations/apolloio/issues/1787220222/?project=222018&referrer=pagerduty_plugin
      return this.props.onHide && this.props.onHide(true);
    }
  };

  getWidthStyle = () => {
    const { width, sidebar } = this.props;

    if (sidebar) {
      return styles.wide;
    }

    if (!width) {
      return styles.mediumWidth;
    }

    const mapping = {
      narrow: styles.narrowWidth,
      small: styles.smallWidth,
      medium: styles.mediumWidth,
      wide: styles.wideWidth,
      ultraWide: styles.ultraWideWidth,
      fullScreen: styles.fullScreenModal,

      sidebar: styles.sidebarWidth,
      custom: null,
    };

    return mapping[width];
  };

  render() {
    const {
      children,
      className,
      onHide,
      secondary,
      verticalCenter,
      sidebar,
      responsive,
      modalOverlayClass,
      backdropClassName,
      dialogClassName,
      clamped,
      withSeparateSidebar,
      dataCy,
      dataTestId,
      modalRestWrapperClassName,
    } = this.props;

    const modalProps = pick(this.props, MODAL_PROP_KEYS);

    const classes = classnames(
      // 20171120JP: We need these global styles when the modal is injected outside our normal zp dom,
      //  like in the Gmail extension.
      'apolloio-css-vars-reset',
      'zp',
      'zp-modal',

      styles['zp-modal'],
      secondary && styles.secondary,
      modalOverlayClass,
    );

    const childrenWithProps = React.Children.map(children, (child) => {
      // 20171031JP: Sometimes a child might be `false` - probably a bug in showDialog.
      if (!child || !child.props) {
        return child;
      }

      return React.cloneElement(child, {
        onHide,
      });
    });

    const widthStyle = this.getWidthStyle();

    /*
      If a modal with a popover inside is open and the popover has an input inside,
        the input's focus won't work properly and will just trigger `onBlur` the moment
        you try to focus the input. Setting `enforceFocus` to `false` fixes that.
    */
    return (
      <ModalOverlay
        {...modalProps}
        className={classes}
        enforceFocus={false}
        backdropClassName={classnames(styles['zp-modal-backdrop'], backdropClassName)}
        data-testid={dataTestId}
        data-cy={dataCy}
        data-ds-legacy-modal
      >
        <div
          className={classnames(
            verticalCenter ? styles['zp-modal-center'] : styles['zp-modal-scroll'],
            dialogClassName,
          )}
        >
          <div
            ref={this.modalInnerRef}
            className={classnames(
              className,
              'zp-modal-content',
              styles['zp-modal-content'],
              widthStyle,
              sidebar && styles.sidebar,
              responsive && styles.responsive,
              clamped && styles.clampedModal,
              withSeparateSidebar && styles.withSeparateSidebar,
            )}
          >
            <ResetWrapper className={modalRestWrapperClassName}>{childrenWithProps}</ResetWrapper>
          </div>
        </div>
      </ModalOverlay>
    );
  }
}

export const ModalForStory = Modal;
export default connect(null, null)(Modal);

type ModalHeaderProps = PropsWithChildren<{
  className?: string;
  closeButton?: boolean;
  closeClassName?: string;
  onHide?(...args: unknown[]): unknown;
  onDismiss?(...args: unknown[]): unknown; // If onDimiss is passed as a prop, it will override onHide
  sideDrawer?: boolean;
  disableCloseButton?: boolean;
}>;

export class ModalHeader extends Component<ModalHeaderProps> {
  static defaultProps = {
    className: '',
    closeButton: false,
    onHide: null,
    onDismiss: null,
    sideDrawer: false,
    closeClassName: '',
    disableCloseButton: false,
  };

  render() {
    const {
      children,
      className,
      closeButton,
      closeClassName,
      sideDrawer,
      disableCloseButton,
      onHide,
      onDismiss,
    } = this.props;

    if (sideDrawer) {
      return (
        <GenericWrapperWithClassName
          baseClassName={styles['zp-modal-header']}
          className={className}
        >
          {closeButton && (
            <div>
              <Icon
                name="arrow-right"
                className={styles.closeSideDrawer}
                onClick={onDismiss ?? onHide}
                disabled={disableCloseButton}
              />
            </div>
          )}
          {children}
        </GenericWrapperWithClassName>
      );
    }

    return (
      <GenericWrapperWithClassName baseClassName={styles['zp-modal-header']} className={className}>
        {children}
        {closeButton && (
          <Icon
            name="close"
            className={classnames(styles.close, closeClassName)}
            onClick={onDismiss ?? onHide}
            disabled={disableCloseButton}
          />
        )}
      </GenericWrapperWithClassName>
    );
  }
}

type ModalTitleProps = {
  className?: string;
};

export class ModalTitle extends Component<ModalTitleProps> {
  render() {
    const { children, className } = this.props;
    return (
      <GenericWrapperWithClassName baseClassName={styles['zp-modal-title']} className={className}>
        {children}
      </GenericWrapperWithClassName>
    );
  }
}

type ModalBodyProps = {
  noPadding?: boolean;
  className?: string;
  onScroll?(...args: unknown[]): unknown;
  onClick?(...args: unknown[]): unknown;
};

export class ModalBody extends Component<ModalBodyProps> {
  static defaultProps = {
    onScroll: () => {},
    onClick: () => {},
    className: '',
    noPadding: false,
  };

  render() {
    const { children, className, noPadding, onScroll, onClick } = this.props;

    const classes = classnames(styles['zp-modal-body'], noPadding && styles['no-padding']);

    return (
      <GenericWrapperWithClassName
        baseClassName={classes}
        className={className}
        onScroll={onScroll}
        onClick={onClick}
      >
        {children}
      </GenericWrapperWithClassName>
    );
  }
}

type ModalFooterProps = {
  className?: string;
  shiftContentToRight?: boolean;
};

export class ModalFooter extends Component<ModalFooterProps> {
  static defaultProps = {
    className: '',
    shiftContentToRight: false,
  };

  render() {
    const { children, className, shiftContentToRight, ...restProps } = this.props;
    const additionalClasses = shiftContentToRight ? styles.modalFooterShiftRight : '';
    return (
      <GenericWrapperWithClassName
        baseClassName={styles['zp-modal-footer']}
        className={classnames(additionalClasses, className)}
        {...restProps}
      >
        {children}
      </GenericWrapperWithClassName>
    );
  }
}

type ModalSidebarProps = PropsWithChildren<{
  className?: string;
}>;

export function ModalSidebar({ children, className }: ModalSidebarProps) {
  return (
    <GenericWrapperWithClassName baseClassName={styles['zp-modal-sidebar']} className={className}>
      {children}
    </GenericWrapperWithClassName>
  );
}
