import { invert, isDate } from 'lodash-es';
import moment, { type Moment } from 'moment';
import { getTimezoneOffset } from 'date-fns-tz';
import { differenceInMinutes, format, parseISO } from 'date-fns';

const usStateMapping = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const treatAsUTC = (date: string | number | Date) => {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
};

export function replaceCalcuttaWithKolkata(timeZone: string) {
  return timeZone.replace('Calcutta', 'Kolkata'); //Our code base has Kolkata and Intl is returning Calcutta
}

export function unabbreviateUsState(usStateAbbreviation: string) {
  if (!usStateAbbreviation || usStateAbbreviation.length !== 2) {
    return null;
  }

  return usStateMapping[usStateAbbreviation.toUpperCase()];
}

export function abbreviateUsState(usState: string | number) {
  return invert(usStateMapping)[usState];
}

export function getDays() {
  return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
}

type GetTimeOptionsResponse = { [key: string]: { date: Date | null; iconName: string } };

export function getDateTimeOptions(): GetTimeOptionsResponse {
  const now = new Date();
  return {
    Immediately: { date: now, iconName: 'clock-outline' },
    'In 1 hour': { date: addHours(now, 1), iconName: 'clock-outline' },
    'In 2 hours': { date: addHours(now, 2), iconName: 'clock-outline' },
    'In 24 hours': { date: addHours(now, 24), iconName: 'clock-outline' },
    'Next business day morning': {
      date: assembleDate(getNextBusinessDay(), '08:00'),
      iconName: 'briefcase',
    },
    'Next business day afternoon': {
      date: assembleDate(getNextBusinessDay(), '15:00'),
      iconName: 'briefcase',
    },
    'In 7 days': { date: nDaysFromNow(7), iconName: 'calendar' },
    'In 30 days': { date: nDaysFromNow(30), iconName: 'calendar' },
  };
}

function getStartOfTheDay(date: Date) {
  const newDate = new Date(date.getTime());
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

function calculateEndOfDayAfterAddingDays(date: Date, nDays: number) {
  const newDate = addDays(date, nDays);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

function getStartOfTheQuarter() {
  return moment().startOf('quarter').toDate();
}

function getEndOfTheQuarter() {
  return moment().endOf('quarter').toDate();
}

function getStartAndEndOfLastQuarter() {
  const previousQuarter = moment().subtract(1, 'quarter');
  return [previousQuarter.startOf('quarter').toDate(), previousQuarter.endOf('quarter').toDate()];
}

export function getStartOfTheWeek() {
  return getThisMonday();
}

export function getEndOfTheWeek() {
  return calculateEndOfDayAfterAddingDays(getThisMonday(), 6);
}

export function getDateRangeOptions() {
  const thisMonday = getThisMonday();
  const now = new Date();
  return {
    Default: [nDaysFromNow(-7), nDaysFromNow(5)],
    Today: [getStartOfTheDay(now), now],
    Yesterday: [nDaysFromNow(-1), nDaysFromNow(-1)],
    'This week': [thisMonday, calculateEndOfDayAfterAddingDays(thisMonday, 6)],
    'Next week': [addDays(thisMonday, 7), calculateEndOfDayAfterAddingDays(thisMonday, 13)],
    'Last week': [addDays(thisMonday, -7), calculateEndOfDayAfterAddingDays(thisMonday, -1)],
    'This month': [getCurrentMonthStart(), getCurrentMonthEnd()],
    'Last month': [getPreviousMonthStart(), getPreviousMonthEnd()],
    'Last 7 days': [nDaysFromNow(-7), now],
    'Last 30 days': [nDaysFromNow(-30), now],
    'This quarter': [getStartOfTheQuarter(), getEndOfTheQuarter()],
    'Last quarter': getStartAndEndOfLastQuarter(),
  };
}

export function getRailsTimezone(cityTimezone: string | number) {
  const inverseSupportedTimezones = invert(supportedTimezones);
  return inverseSupportedTimezones[cityTimezone] || '';
}

export function getUsersCurrentTimeZone() {
  if (!isUserTimeZoneValid()) {
    return 'Etc/UTC';
  }
  return replaceCalcuttaWithKolkata(Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function isUserTimeZoneValid() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return !(timeZone === null || timeZone === undefined || timeZone.includes('Etc/Unknown'));
}

export function addMinutes(date: { getTime: () => string | number | Date }, nMinutes: number) {
  const clonedDate = new Date(date.getTime());
  clonedDate.setTime(clonedDate.getTime() + nMinutes * 60 * 1000);
  return clonedDate;
}

export function setHours(date: Date, nHours: number) {
  const clonedDate = new Date(date);
  clonedDate.setHours(nHours);
  clonedDate.setMinutes(0);
  clonedDate.setSeconds(0);
  return clonedDate;
}

export function addHours(date: Date, nHours: number) {
  const clonedDate = new Date(date.getTime());
  clonedDate.setTime(clonedDate.getTime() + nHours * 60 * 60 * 1000);
  return clonedDate;
}

export function addDays(date: Date, nDays: number) {
  const clonedDate = new Date(date.getTime());
  clonedDate.setDate(clonedDate.getDate() + nDays);
  return clonedDate;
}

export function addMonths(date: { getTime: () => string | number | Date }, nMonths: number) {
  const clonedDate = new Date(date.getTime());
  return new Date(clonedDate.setMonth(clonedDate.getMonth() + nMonths));
}

export function addQuarters(date, nQuarters) {
  return new Date(moment(date).add(3 * nQuarters, 'months'));
}

export function nDaysFromNow(n: number) {
  const date = new Date();
  return addDays(date, n);
}

export function getTomorrow() {
  return nDaysFromNow(1);
}

export function getNextBusinessDay() {
  let date = getTomorrow();
  while (date.getDay() == 0 || date.getDay() == 6) {
    date = addDays(date, 1);
  }
  return date;
}

export function getThisMonday(useMomentToGetStartOfDay?: boolean) {
  let date = new Date();
  while (date.getDay() !== 1) {
    date = addDays(date, -1);
  }

  if (useMomentToGetStartOfDay) {
    return moment(date).startOf('day').toDate();
  }

  return getStartOfTheDay(date);
}

//Returns the first of the current month
export function getCurrentMonthStart() {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 1);
}

export function getNextMonthStart() {
  const monthStart = getCurrentMonthStart();
  const nextMonthRandomDay = addDays(monthStart, 32);
  return new Date(nextMonthRandomDay.getFullYear(), nextMonthRandomDay.getMonth(), 1, 0, 0, 0, 1);
}

export function getCurrentMonthEnd() {
  const nextMonthStart = getNextMonthStart();
  return calculateEndOfDayAfterAddingDays(nextMonthStart, -1);
}

export function getPreviousMonthEnd() {
  const currentMonthStart = getCurrentMonthStart();
  return calculateEndOfDayAfterAddingDays(currentMonthStart, -1);
}

export function getPreviousMonthStart() {
  const previousMonthEnd = getPreviousMonthEnd();
  return new Date(previousMonthEnd.getFullYear(), previousMonthEnd.getMonth(), 1, 0, 0, 0, 1);
}

// Handy function for, say:
// "Tomorrow" at "15:30"
// Or "Next business day" at "08:00"

// 20180612JP: Params are messy, seems to be called with:
//  `date` as one of [Date, Moment, String]
export function assembleDate(date: string | Date, time: string) {
  if (!date || !time) {
    return null;
  }

  let nativeDate: string | Date;
  if (isDate(date)) {
    nativeDate = date;
  } else if (moment.isMoment(date)) {
    nativeDate = date.toDate();
  } else {
    nativeDate = moment(date).toDate();
  }

  const clonedDate = new Date(nativeDate.getTime());
  const stringTime = moment.isMoment(time) ? time.format('HH:mm') : time;
  const splittedTime = (stringTime || '08:00').split(':');
  clonedDate.setHours(parseInt(splittedTime[0]));
  clonedDate.setMinutes(parseInt(splittedTime[1]));
  return clonedDate;
}

export function extractTime(dateTime) {
  if (!dateTime) {
    return null;
  }

  return `${pad(dateTime.getHours(), 2)}:${pad(dateTime.getMinutes(), 2)}`;
}

export function pad(num, size) {
  let s = `${num}`;
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

export function secondsToHHMMSS(seconds: number) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds - hrs * 3600) / 60);
  const secs = seconds - hrs * 3600 - mins * 60;
  let output = '';
  if (hrs > 0) {
    if (hrs < 10) {
      output += '0';
    }
    output += `${hrs}:`;
  }
  if (mins < 10) {
    output += '0';
  }
  output += `${mins}:`;
  if (secs < 10) {
    output += '0';
  }
  output += secs;
  return output;
}

/**
 * The set of time zones we allow the user to send via frontend.
 * Backend counterpart: time_util.rb
 * Any addition/deletion should reflect time_util.rb#SUPPORTED_TIMEZONES file also
 */
export const supportedTimezones = {
  // 'International Date Line West':'Pacific/Midway',
  // 'Midway Island':'Pacific/Midway',
  // 'American Samoa':'Pacific/Pago_Pago',
  // Hawaii: 'Pacific/Honolulu',
  // 'Alaska':'America/Juneau',
  'Pacific Time': 'America/Los_Angeles',
  // 'Tijuana':'America/Tijuana',
  'Mountain Time': 'America/Denver',
  // 'Arizona':'America/Phoenix',
  // 'Chihuahua':'America/Chihuahua',
  // 'Mazatlan':'America/Mazatlan',
  'Central Time': 'America/Chicago',
  // 'Saskatchewan':'America/Regina',
  // 'Guadalajara':'America/Mexico_City',
  // 'Mexico City':'America/Mexico_City',
  // 'Monterrey':'America/Monterrey',
  // 'Central America':'America/Guatemala',
  'Eastern Time': 'America/New_York',
  // 'Indiana (East)':'America/Indiana/Indianapolis',
  // 'Bogota':'America/Bogota',
  // 'Lima':'America/Lima',
  // 'Quito':'America/Lima',
  // 'Atlantic Time (Canada)':'America/Halifax',
  // 'Caracas':'America/Caracas',
  // 'La Paz':'America/La_Paz',
  // 'Santiago':'America/Santiago',
  // 'Newfoundland':'America/St_Johns',
  // 'Brasilia':'America/Sao_Paulo',
  // 'Buenos Aires':'America/Argentina/Buenos_Aires',
  // 'Montevideo':'America/Montevideo',
  // 'Georgetown':'America/Guyana',
  // 'Greenland':'America/Godthab',
  // 'Mid-Atlantic':'Atlantic/South_Georgia',
  // 'Azores':'Atlantic/Azores',
  // 'Cape Verde Is.':'Atlantic/Cape_Verde',
  // 'Dublin':'Europe/Dublin',
  // 'Edinburgh':'Europe/London',
  // 'Lisbon':'Europe/Lisbon',
  // 'Casablanca':'Africa/Casablanca',
  // 'Monrovia':'Africa/Monrovia',
  UTC: 'Etc/UTC',
  London: 'Europe/London',
  // 'Belgrade':'Europe/Belgrade',
  // 'Bratislava':'Europe/Bratislava',
  // 'Budapest':'Europe/Budapest',
  // 'Ljubljana':'Europe/Ljubljana',
  // 'Prague':'Europe/Prague',
  // 'Sarajevo':'Europe/Sarajevo',
  // 'Skopje':'Europe/Skopje',
  // 'Warsaw':'Europe/Warsaw',
  // 'Zagreb':'Europe/Zagreb',
  // 'Brussels':'Europe/Brussels',
  // 'Copenhagen':'Europe/Copenhagen',
  // 'Madrid':'Europe/Madrid',
  Paris: 'Europe/Paris',
  // 'Amsterdam':'Europe/Amsterdam',
  // 'Berlin':'Europe/Berlin',
  // 'Bern':'Europe/Berlin',
  // 'Rome':'Europe/Rome',
  // 'Stockholm':'Europe/Stockholm',
  // 'Vienna':'Europe/Vienna',
  // 'West Central Africa':'Africa/Algiers',
  // 'Bucharest':'Europe/Bucharest',
  // 'Cairo':'Africa/Cairo',
  // 'Helsinki':'Europe/Helsinki',
  // 'Kyiv':'Europe/Kiev',
  // 'Riga':'Europe/Riga',
  // 'Sofia':'Europe/Sofia',
  // 'Tallinn':'Europe/Tallinn',
  // 'Vilnius':'Europe/Vilnius',
  Athens: 'Europe/Athens',
  // 'Istanbul':'Europe/Istanbul',
  // 'Minsk':'Europe/Minsk',
  // 'Jerusalem':'Asia/Jerusalem',
  // 'Harare':'Africa/Harare',
  // 'Pretoria':'Africa/Johannesburg',
  // 'Kaliningrad':'Europe/Kaliningrad',
  // 'Moscow':'Europe/Moscow',
  // 'St. Petersburg':'Europe/Moscow',
  // 'Volgograd':'Europe/Volgograd',
  // 'Samara':'Europe/Samara',
  // 'Kuwait':'Asia/Kuwait',
  // 'Riyadh':'Asia/Riyadh',
  // 'Nairobi':'Africa/Nairobi',
  // 'Baghdad':'Asia/Baghdad',
  // 'Tehran':'Asia/Tehran',
  // 'Abu Dhabi':'Asia/Muscat',
  // 'Muscat':'Asia/Muscat',
  // 'Baku':'Asia/Baku',
  // 'Tbilisi':'Asia/Tbilisi',
  // 'Yerevan':'Asia/Yerevan',
  // 'Kabul':'Asia/Kabul',
  // 'Ekaterinburg':'Asia/Yekaterinburg',
  // 'Islamabad':'Asia/Karachi',
  // 'Karachi':'Asia/Karachi',
  // 'Tashkent':'Asia/Tashkent',
  // 'Chennai':'Asia/Kolkata',
  // 'Kolkata':'Asia/Kolkata',
  // 'Mumbai':'Asia/Kolkata',
  // 'New Delhi':'Asia/Kolkata',
  // 'Kathmandu':'Asia/Kathmandu',
  // 'Astana':'Asia/Dhaka',
  // 'Dhaka':'Asia/Dhaka',
  // 'Sri Jayawardenepura':'Asia/Colombo',
  // 'Almaty':'Asia/Almaty',
  // 'Novosibirsk':'Asia/Novosibirsk',
  // 'Rangoon':'Asia/Rangoon',
  // 'Bangkok':'Asia/Bangkok',
  // 'Hanoi':'Asia/Bangkok',
  // 'Jakarta':'Asia/Jakarta',
  // 'Krasnoyarsk':'Asia/Krasnoyarsk',
  // 'Beijing':'Asia/Shanghai',
  // 'Chongqing':'Asia/Chongqing',
  // 'Hong Kong':'Asia/Hong_Kong',
  // 'Urumqi':'Asia/Urumqi',
  // 'Kuala Lumpur':'Asia/Kuala_Lumpur',
  // 'Singapore':'Asia/Singapore',
  // 'Taipei':'Asia/Taipei',
  'Australia Western Standard Time': 'Australia/Perth',
  // 'Irkutsk':'Asia/Irkutsk',
  // 'Ulaanbaatar':'Asia/Ulaanbaatar',
  // 'Seoul':'Asia/Seoul',
  // 'Osaka':'Asia/Tokyo',
  // 'Sapporo':'Asia/Tokyo',
  // 'Tokyo':'Asia/Tokyo',
  // 'Yakutsk':'Asia/Yakutsk',
  'Australia Central Standard Time': 'Australia/Darwin',
  // 'Adelaide':'Australia/Adelaide',
  // 'Canberra':'Australia/Melbourne',
  // 'Melbourne':'Australia/Melbourne',
  'Australia Eastern Standard Time': 'Australia/Sydney',
  // 'Brisbane':'Australia/Brisbane',
  // 'Hobart':'Australia/Hobart',
  // 'Vladivostok':'Asia/Vladivostok',
  // 'Guam':'Pacific/Guam',
  // 'Port Moresby':'Pacific/Port_Moresby',
  // 'Magadan':'Asia/Magadan',
  // 'Srednekolymsk':'Asia/Srednekolymsk',
  // 'Solomon Is.':'Pacific/Guadalcanal',
  // 'New Caledonia':'Pacific/Noumea',
  // 'Fiji':'Pacific/Fiji',
  // 'Kamchatka':'Asia/Kamchatka',
  // 'Marshall Is.':'Pacific/Majuro',
  // 'Auckland':'Pacific/Auckland',
  // 'Wellington':'Pacific/Auckland',
  // 'Nuku'alofa':'Pacific/Tongatapu',
  // 'Tokelau Is.':'Pacific/Fakaofo',
  // 'Chatham Is.':'Pacific/Chatham',
  // 'Samoa':'Pacific/Apia',
  'Middle East Time': 'Asia/Tehran',
  'Near East Time': 'Asia/Muscat',
  'Pakistan Lahore Time': 'Asia/Karachi',
  'India Standard Time': 'Asia/Kolkata',
  'Bangladesh Standard Time': 'Asia/Dhaka',
  'Vietnam Standard Time': 'Asia/Bangkok',
  'Japan Standard Time': 'Asia/Tokyo',
  'Solomon Standard Time': 'Asia/Vladivostok',
  'New Zealand Standard Time': 'Pacific/Auckland',
  'Midway Islands Time': 'Pacific/Midway',
  'Hawaii Standard Time': 'Pacific/Honolulu',
  'Alaska Standard Time': 'America/Juneau',
  'Puerto Rico and US Virgin Islands Time': 'America/La_Paz',
  'Canada Newfoundland Time': 'America/St_Johns',
  'Brazil Eastern Time': 'America/Sao_Paulo',
} as const;

export function gmtOffsetString(timezone: string) {
  if (!getTimezoneOffset(timezone)) {
    return '';
  }
  const offset = getTimezoneOffset(timezone) / (1000 * 60);
  const offsetHours = Math.trunc(offset / 60);
  const offsetMinutes = Math.abs(offset % 60);

  const offsetString = `GMT${offset >= 0 ? '+' : '-'}${Math.abs(offsetHours)
    .toString()
    .padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
  return offsetString;
}

export function getAmPmTime(number: number) {
  let ampm = 'pm';
  let hour = number;

  if (number < 12 || number === 24) {
    ampm = 'am';
  }

  if (number > 12) {
    hour = number - 12;
  } else if (number === 0) {
    hour = 12;
  } else {
    hour = number;
  }

  return `${hour} ${ampm}`;
}

export function getRelativeTime(time, emptyValue) {
  let days: number;
  let daysDelta: number;
  let distanceMillis: number;
  let hours: number;
  let minutes: number;
  let months: number;
  let monthsDelta: number;
  let res: string;
  let seconds: number;
  let value: string | number | Date;
  let years: number;
  if (!(value = time)) {
    return emptyValue;
  }
  distanceMillis = Math.abs(new Date().getTime() - new Date(value).getTime());
  seconds = Math.abs(distanceMillis) / 1e3;
  minutes = seconds / 60;
  hours = minutes / 60;
  days = hours / 24;
  months = days / 30;
  years = days / 365;
  res = '';
  if (seconds < 60) {
    res = '<1m';
  } else if (minutes < 60) {
    res = `${Math.round(minutes)}m`;
  } else if (hours < 24) {
    res = `${Math.round(hours)}h`;
  } else if (days < 30) {
    res = `${Math.round(days)}d`;
  } else if (months < 12) {
    daysDelta = (months - Math.floor(months)) * 30;
    res = `${Math.floor(months)}m ${Math.ceil(daysDelta)}d`;
  } else {
    monthsDelta = (years - Math.floor(years)) * 12;
    res = `${Math.floor(years)}y ${Math.ceil(monthsDelta)}m`;
  }
  return res;
}

export function daysBetween(startDate: string | number | Date, endDate: string | number | Date) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

/**
 * Returns the difference between two moments in hours (can be negative).
 *
 * @param {Moment} start
 * @param {Moment} end
 *
 */
export function hoursBetween(start: Moment, end: Moment): number {
  const duration = moment.duration(start.diff(end));
  return duration.asHours();
}

export function getOrdinal(n: string | number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function gmtTimezones() {
  const gmtZones = {};
  // To get the list of supported gmt timezones see time_util.rb#TimeUtil.all_supported_gmt_timezones
  [
    -600, -480, -420, -360, -300, -240, -180, -60, 0, 60, 120, 180, 240, 270, 300, 330, 345, 360,
    390, 420, 480, 540, 570, 600, 660, 720, 780, 840,
  ].forEach((offset) => {
    const gmtStr = `GMT ${offset > 0 ? '+' : ''}${Math.floor(offset / 60)}:${(offset % 60)
      .toString()
      .padStart(2, '0')}`;
    gmtZones[gmtStr] = offset;
  });
  return gmtZones;
}

export function timeToMMMMDYYYYhmmAFormat(dateString: string) {
  const date = moment(dateString);
  if (date.isValid()) {
    return moment(new Date(date)).format('MMMM D, YYYY [at] h:mmA');
  }
  return '';
}

export function dateStringToMMMDYYYYFormat(dateString) {
  const date = moment(dateString);
  if (date.isValid()) {
    return date.format('MMM D, YYYY');
  }
  return '';
}

export function secondsToDHMS(secs: number, shortFormat = false, compactFormat = false) {
  const days = Math.floor(secs / (3600 * 24));
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs % 3600) / 60);
  const seconds = Math.floor((secs % 3600) % 60);

  if (shortFormat) {
    if (days) {
      return days + (days === 1 ? ' day' : ' days');
    } else if (hours) {
      return hours + (hours === 1 ? ' hour' : ' hours');
    } else if (minutes) {
      return minutes + (minutes === 1 ? ' minute' : ' minutes');
    } else {
      return seconds + (seconds === 1 ? ' second' : ' seconds');
    }
  }

  if (compactFormat) {
    const formattedStr = [];
    if (days) {
      formattedStr.push(`${days}d`);
    }
    if (hours) {
      formattedStr.push(`${hours}h`);
    }
    if (minutes) {
      formattedStr.push(`${minutes}m`);
    }
    if (seconds) {
      formattedStr.push(`${seconds}s`);
    }
    if (formattedStr.length === 0) {
      return '0s';
    }
    return formattedStr.join('');
  }

  return {
    days,
    hours,
    minutes,
    seconds,
  };
}

export function millisecondsToSeconds(milliseconds: number) {
  return Math.trunc(milliseconds) / 1000 + parseFloat((milliseconds % 1).toFixed(1));
}

export function yearMonthsToDays(year: string, month: string) {
  if (
    (year === null || year === '' || year === undefined) &&
    (month === null || month === '' || month === undefined)
  ) {
    return null;
  }
  return ((year || 0) * 365 + (month || 0) * 30).toString();
}

export function daysToYearMonths(days: string | number) {
  if (days === null || days === '' || days === undefined) {
    return {
      years: null,
      months: null,
    };
  }
  let years = Math.floor(days / 365);
  const remainingDays = days % 365;
  let months = Math.ceil(remainingDays / 30);
  if (months === 12) {
    years += 1;
    months = 0;
  }
  return {
    years,
    months,
  };
}

/**
 *
 * @param {string} dateString
 * @param {number} nMinutes
 *
 */
export function isInPastOrNMinutesFromNow(dateString: string, nMinutes: number): boolean {
  return differenceInMinutes(dateString, Date.now()) <= nMinutes;
}

/**
 *
 * @param {number} time
 * @param {number} timezoneOffset
 *
 */
export function convertToUTC(time: number, totalOffsetMinutes: number) {
  const inputHours = Math.floor(time / 100);
  const inputMinutes = time % 100;
  let utcMinutes = inputHours * 60 + inputMinutes - totalOffsetMinutes;
  if (utcMinutes < 0) {
    utcMinutes += 1440; // 24 hours in minutes
  }
  const utcHours = Math.floor(utcMinutes / 60);
  const utcMinutesResult = utcMinutes % 60;
  const hoursResult = utcHours.toString().padStart(2, '0');
  const minutesResult = utcMinutesResult.toString().padStart(2, '0');

  return Number(hoursResult + minutesResult);
}

export function getTotalDaysInclusive(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return endMoment.diff(startMoment, 'days') + 1;
}

export function formatDateToLocalDate(date: string, dateFormat: string) {
  return format(new Date(parseISO(date)), dateFormat);
}
